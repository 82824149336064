// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/hooks/useConvertCurrency.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/hooks/useConvertCurrency.tsx");
  import.meta.hot.lastModified = "1727238604000";
}
// REMIX HMR END

import { createContext, useState, useContext } from 'react';
const defaultValue = {
  isConverted: false,
  setConversion: () => {}
};
const ConvertCurrencyContext = createContext(defaultValue);
export const ConvertCurrencyProvider = ({
  children
}) => {
  _s();
  const [conversion, setConversionState] = useState({
    isConverted: false
  });
  const setConversion = (isConverted, convertedTo) => {
    setConversionState({
      isConverted,
      convertedTo
    });
  };
  return <ConvertCurrencyContext.Provider value={{
    ...conversion,
    setConversion
  }}>
      {children}
    </ConvertCurrencyContext.Provider>;
};
_s(ConvertCurrencyProvider, "0F5FSZ+qVlgOtco7i/e9kk2fY4Q=");
_c = ConvertCurrencyProvider;
export const useConvertCurrencyContext = () => {
  _s2();
  return useContext(ConvertCurrencyContext);
};
_s2(useConvertCurrencyContext, "gDsCjeeItUuvgOWf1v4qoK9RF6k=");
var _c;
$RefreshReg$(_c, "ConvertCurrencyProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;